import { template as template_826ee81c121f4e6988ed92e63df20fe3 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_826ee81c121f4e6988ed92e63df20fe3(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
