import { template as template_f5d3d7b3bbe14ebaa3980995e2e68266 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_f5d3d7b3bbe14ebaa3980995e2e68266(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
