import { template as template_a502ee59025142c08a084352fa0e02d1 } from "@ember/template-compiler";
const WelcomeHeader = template_a502ee59025142c08a084352fa0e02d1(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
