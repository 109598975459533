import { template as template_d849ab3cdb234e688e5ecdcb99838b1f } from "@ember/template-compiler";
const FKText = template_d849ab3cdb234e688e5ecdcb99838b1f(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
